<template>
  <div class="wp">
    <div class="m-topb1">
      <div class="left">
        <ul class="ul-tabs">
          <li>
            <div class="con" :class="act==0?'on':''" @click="tabck(0)">{{$t('machine.Machine')}}</div>
          </li>
          <li>
            <div class="con" :class="act==1?'on':''" @click="tabck(1)">{{$t('machine.Pledge')}}</div>
          </li>
          <li>
            <div class="con" :class="act==2?'on':''" @click="tabck(2)">{{ $t('machine.My') }}</div>
          </li>
        </ul>
      </div>
      <div class="right">
        <van-popover v-model="showPopover" placement="bottom-end" trigger="click">
          <div class="p-tips"></div>
          <template #reference>
            <van-icon name="info-o" color="#fff"></van-icon>
          </template>
        </van-popover>
      </div>
    </div>
    <div class="m-box1">
      <div class="g-topb1">
        <div class="left">
          <div class="tit"><span class="h3">{{ $t('machine.Fastliquiditypledges') }}</span></div>
        </div>
        <div class="right">
          <div class="btn">{{ $t('machine.Apply') }}</div>
        </div>
      </div>
      <div class="m-card1">
        <div class="item">
          <div class="box"><span class="s">{{ $t('machine.Price') }}:</span><span class="txt">100 USDT</span></div>
        </div>
        <div class="item">
          <div class="box"><span class="s">{{ $t('machine.DailyRate') }}:</span><span class="txt">5.8%</span></div>
        </div>
        <div class="item">
          <div class="box"><span class="s">{{ $t('machine.Cycle') }}:</span><span class="txt">1 {{ $t('machine.Day') }}</span></div>
        </div>
      </div>
    </div>
  </div>

  <div class="rightS">
    <a :href="customerService" class="item">
      <img src="~@/assets/kefu.png" alt="">
    </a>
  </div>

  <van-tabbar route v-model="tabactive" active-color="#f4c620" inactive-color="#6f6f6f" z-index="100" placeholder
              :border="false">
    <van-tabbar-item v-for="(item,index) in tabbar" :key="item.name" :url="item.url">
      <span>{{ item.name }}</span>
      <template #icon="props">
        <img :src="props.active || index === tabactive ? item.icon.inactive : item.icon.active" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import {customerServiceList} from "@/api/customer_service";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Machine",
  data () {
    return {
      act:0,
      showPopover: false,
      tabactive: 2,
      customerService:"",
      tabbar: [
        {
          active:true,
          name: this.$i18n.messages[this.$i18n.locale]["title"]['home'],
          url: "/#/index",
          icon: {
            active: require('@/assets/tabber/t1.png'),
            inactive: require('@/assets/tabber/t1-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['pool'],
          url: "/#/pool",
          icon: {
            active: require('@/assets/tabber/t2.png'),
            inactive: require('@/assets/tabber/t2-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['machine'],
          url: "/#/machine",
          icon: {
            active: require('@/assets/tabber/t3.png'),
            inactive: require('@/assets/tabber/t3-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['mime'],
          url: "/#/mine",
          icon: {
            active: require('@/assets/tabber/t4.png'),
            inactive: require('@/assets/tabber/t4-1.png'),
          },
        }
      ]
    }
  },
  methods: {
    tabck (num) {
      this.act = num
    }
  },

  mounted () {
    customerServiceList().then(response => {
      if (response.code === 200) {
        this.customerService = response.data.configValue
      }
    })
  }
}
</script>

<style scoped>

</style>
